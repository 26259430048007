













































import Vue from 'vue';
import { TableActionType } from '@/interface/costQuotaLibrary';
import { MaterialType } from '@/interface/materialMachineLibrary';
import BasicInfo from './basicInfo.vue';
import BaseTable from '../component/materialMachineTable.vue';
import EditForm from '../component/EditForm.vue';
import {
  getMaterialHistory,
  updateHistory,
  deleteHistory,
  postDetailAdd,
} from '@/api/materialMachineLibrary';
import { mapGetters } from 'vuex';
import { getKindText } from '@/views/enterpriseLibrary';
const actionList = [
  {
    type: TableActionType.EDIT,
    name: '编辑',
  },
  {
    type: TableActionType.DELETE,
    name: '移除',
  },
];
const TitleMap = new Map([
  ['create', '新增材价'],
  ['edit', '编辑明细'],
]);
export default Vue.extend({
  name: 'materialMachineDetail',
  props: {},
  data() {
    return {
      materialInfo: {} as MaterialType,
      selectMaterial: {} as MaterialType,
      editVisible: false,
      actionType: 'create',
      delVisible: false,
      actionList,
      tableData: [] as MaterialType[],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    delTipText(): string {
      return this.tableData.length <= 1
        ? '请保留至少一条数据，删除会影响后续组价精度'
        : '是否删除当前价格明细？';
    },
    editTitle(): string {
      return TitleMap.get(this.actionType) || '';
    },
    basicInfoList() {
      let list = [
        {
          name: '材机名称',
          key: 'materialName',
          value: '',
        },
        {
          name: '类别',
          key: 'kind',
          value: '',
        },
        {
          name: '规格型号',
          key: 'specification',
          value: '',
        },
        {
          name: '计量单位',
          key: 'unit',
          value: '',
        },
        {
          name: '均价(元)',
          key: 'avgPrice',
          value: '',
        },
      ];
      const materialInfo = this.materialInfo;
      for (let item of list) {
        const key = item.key;
        if (key) {
          item.value =
            (key === 'kind'
              ? getKindText(materialInfo[key])
              : materialInfo[key]) || '--';
        }
      }
      return list;
    },
  },
  created() {
    this.materialInfo = JSON.parse(
      sessionStorage.getItem('currentMaterialInfo') || '{}'
    );
    this.getHistory();
  },
  methods: {
    getHistory() {
      const { current, pageSize } = this.pagination;
      const params = {
        page: current,
        limit: pageSize,
        agencyCode: this.userInfo?.agencyCode,
        stlRcjId: this.materialInfo?.sequenceNbr,
      };
      getMaterialHistory(params).then(
        ({
          status,
          result: {
            avgPrice,
            pageData: { total, list },
          },
        }) => {
          if (status === 200) {
            this.pagination.total = total;
            this.tableData = list;
            this.$set(this.materialInfo, 'avgPrice', avgPrice);
          }
        }
      );
    },
    paginationChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination = {
        ...this.pagination,
        current,
        pageSize,
      };
      this.getHistory();
    },
    create() {
      if (!Object.keys(this.materialInfo).length) return false;
      this.editVisible = true;
      this.actionType = 'create';
      this.selectMaterial = this.materialInfo;
      this.$set(this.selectMaterial, 'marketPrice', this.materialInfo.dePrice);
    },
    actionHandler({ record, key, index, e }) {
      this.selectMaterial = record;
      if (key === TableActionType.EDIT) {
        this.editVisible = true;
        this.actionType = 'edit';
      }
      if (key === TableActionType.DELETE) {
        this.delVisible = true;
      }
    },
    delSave() {
      if (this.tableData.length <= 1) {
        this.delVisible = false;
        return;
      }
      this.delVisible = false;
      const { serialNumber, sequenceNbr } = this.selectMaterial;
      deleteHistory(sequenceNbr, serialNumber).then(({ status, result }) => {
        if (status === 200 && result) {
          this.editVisible = false;
          this.getHistory();
          return this.$message.success('删除成功');
        }
        this.$message.error('删除失败');
      });
    },
    editSave(formData) {
      if (this.actionType === 'create') {
        postDetailAdd(formData).then(({ status, result }) => {
          this.tipHandler(status, result, '新增');
        });
        return;
      }
      updateHistory(formData).then(({ status, result }) => {
        this.tipHandler(status, result, '修改');
      });
    },
    tipHandler(status: number, result: boolean, text: string) {
      if (status === 200 && result) {
        this.editVisible = false;
        this.getHistory();
        return this.$message.success(`${text}成功`);
      }
      this.$message.error(`${text}失败`);
    },
  },
  components: {
    BasicInfo,
    BaseTable,
    EditForm,
  },
});
